.new-table-container th {
  position: relative;
}
.resizer {
  position: absolute;
  opacity: 0;
  top: 0;
  right: 0;
  height: 100%;
  width: 5px;
  background: #8E8E8D;
  cursor: col-resize;
  user-select: none;
  touch-action: none;
  border-radius: 6px;
}
.resizer.isResizing {
  background: #fea501;
  opacity: 1;
}
*:hover > .resizer {
  opacity: 1;
}

/* thead, th, td, table{
  --tw-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgba(0, 0, 0, 0.102);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
} */

.new-table-container tr:hover td {
  background-color: rgb(241 245 249 / 1);
}

.new-table-container tr[data-state=selected] td {
  background-color: rgb(241 245 249 / 1) !important;
}

.new-table-container table {
  /* box-shadow: -16px 11px 15px 4px rgba(0,0,0,0.1); */
  /* border-radius: 9px; */
  border: none;
  outline: unset;
}

.new-table-container table tbody tr td:last-child {
  box-shadow: 0px 1px 0.3px 0.3px rgba(0,0,0,0.1);
}

/* thead, th, td, table{
--tw-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgba(0, 0, 0, 0.102);
box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
} */
th {
box-shadow: inset 1px -1px 0px 0px rgba(0,0,0,0.1);
background-color: #d1d1d1;
}

.new-table-container table tbody tr td {
background-color: white;
/* box-shadow: -1px -2px 0.3px 0.3px rgba(0,0,0,0.1); */
border-left: 1px solid rgba(0,0,0,0.2);
}

.new-table-container tr:hover td {
background-color: rgb(241 245 249 / 1);
}

.new-table-container tr[data-state=selected] td {
background-color: rgb(241 245 249 / 1) !important;
}

.new-table-container table {
/* box-shadow: -16px 11px 15px 4px rgba(0,0,0,0.1); */
/* border-radius: 9px; */
border: none;
outline: unset;
}

.box-shadow-custom {
box-shadow: -16px 11px 15px 4px rgba(0,0,0,0.1);
}

.new-table-container table tbody tr td:last-child {
box-shadow: 0px 1px 0.3px 0.3px rgba(0,0,0,0.1);
}

.new-table-container table tbody tr td:first-child {
border-left: none;
}

.new-table-container table tbody tr:last-child td {
box-shadow: 1px 0px 0.3px 0.3px rgba(0,0,0,0.1);
}

.new-table-container table tbody tr:last-child td:last-child {
box-shadow: none;
}

.new-table-container table thead tr th:first-child {
padding-left: 0;
padding-right: 0;
/* box-shadow: inset -1px -1px 0px 0px rgba(0,0,0,0.1) inset; */
box-shadow: rgba(0, 0, 0, 0.1) -5px 1px 4px -4px inset, rgba(0, 0, 0, 0.1) 0px -1px 0px 0px inset
}

/* .new-table-container table thead tr th:first-child {
box-shadow: 1px 2px 5px 5px rgba(0,0,0,0.1);
} */

.new-table-container::-webkit-scrollbar {
display: none;
}

.new-table-container:hover::-webkit-scrollbar {
display: block;
height: 5px;
width: 5px;
}
.new-table-container::-webkit-scrollbar-track {
/* box-shadow: inset 0 0 6px rgba(0,0,0,0.1); */
/* background-color: #F5F5F5; */
/* border-radius: 10px; */
}
.new-table-container::-webkit-scrollbar-thumb {
background: linear-gradient(45deg, orange, darksalmon);
border-radius: 10px;
width: 6px;
}

/* @media (min-width: 1280px) and (max-width: 1536px) {
.new-table-container table tbody tr td {
  padding: 5px 0 !important;
}
} */
.new-table-container table tbody tr:has(.sto-manually-created) td,
.new-table-container table tbody td:has(.sto-manually-created) {
/* background-color: #E2fff1 !important; */
background-color: #E1F4F4 !important;
/* background-color: #F0E68C !important; */
}
/* .new-table-container table tbody tr:has(.sto-manually-created):hover td,
.new-table-container table tbody tr:hover td:has(.sto-manually-created) {
background-color: #c8eae1 !important;
} */

.new-table-container table tbody tr:has(.sto-edited) td,
.new-table-container table tbody td:has(.sto-edited) {
background-color: #EDEBFF !important;
}

/* .new-table-container table tbody tr:has(.sto-edited):hover td,
.new-table-container table tbody tr:hover td:has(.sto-edited) {
background-color: #c7deeb !important;
} */.data-subrow-table{
   background: #f1f1f1;
   color: #020817 !important;
}
.data-subrow-table tr{
  border-bottom: 1px solid #cad4e0;
}
.data-subrow-table th,
.data-subrow-table td{
  box-shadow: none !important;
  border: none;
  border-left: none !important;
  background-color: #f1f1f1 !important;
  font-family: Inter;
  padding-top: 0px!important;
  padding-bottom: 0px !important;
   color: #020817 !important;
}
.data-subrow-table th{
  font-size: 13px;
   font-weight: 600;
}
.data-subrow-table td{
  font-size: 12px;
  font-weight: 400;
}
.data-subrow-table th:first-child{
  padding-left: 80px !important;
  text-align: center;
}
.data-subrow-table td:first-child{
  text-align: center;
}

.data-subrow-table th:not(:first-child)
{
  text-align: center;
}
.new-table-container table td:has(.data-subrow-table)
{
  padding: 0px !important;
}
.data-subrow-table td:first-child div div{
  padding-left: 80px;
}