.network-vis {
  position: relative;
  left: 0;
  top: 0;
  width: 100%;
  height: 80vh;
  margin: auto;
  background-color: white;
  padding-top: 12px;
  border-radius: 10px;
}
.network-vis-navbar {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  padding-left: 14px;
  padding-right: 14px;
  border: none !important;
}
.network-vis-navbar-right {
  display: flex;
  justify-content: space-between;
  width: 30%;
  gap: 10px;
  height: 35px;
}
.top1 {
  background-color: blue;
}

.tooltip {
  background-color: #e6f2ff;
  border: 1px solid;
  margin: -8px;
  border-radius: 4px;
  border: none;
  padding: 6px;
  box-shadow: 0 0 3px rgba(56, 54, 54, 0.86);
}
.tooltip p {
  font-size: 5px;
}

.tooltip span {
  font-weight: bold;
}
.filter-btn {
  background: transparent;
  padding: 0;
  margin: 0;
  border: 1px solid #e0e3eb;
  outline: none;
  position: relative;
  align-self: center;
  height: 35px;
  width: 35px;
}
.buttons {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 12px;
  margin-bottom: 20px;
  font-family: Arial, Helvetica, sans-serif;
}

.buttons button {
  margin: 0px 5px;
  background-color: #a264c0;
  color: white;
  font-size: 6px;
  padding: 3px 3px;
  border: none;
  outline: none;
}

.buttons button:focus {
  border: none;
  outline: none;
}
.count {
  display: flex;
  justify-content: center;
  position: absolute;
  top: 40;
  left: 70;
  margin-bottom: 6px;
  z-index: 1000;
}

.count div {
  display: flex;
  background: white;
  box-shadow: 0px 0px 5px 0px #e2e2e2;
  align-items: center;
  margin: 0px 6px;
  padding: 6px;
  justify-content: space-around;
  border-radius: 10px;
  font-family: Arial, Helvetica, sans-serif;
}

.count h3 {
  font-size: 30px;
  width: 80%;
  margin-right: 18px;
}

.count p {
  font-size: 9px;
}
.default-btn {
  background-color: #ffffff;
  border-radius: 5px;
  height: 30px;
  width: 30px;
  border: 1px solid #e0e3eb;
  color: #3d445c;
  padding: 5px;
  position: absolute;
  bottom: 130px;
  left: 15px;
  outline: none;
}
.default-btn:hover,
.default-btn:active,
.default-btn:focus {
  border: 1px solid #e0e3eb;
  outline: none;
}
.input-container {
  display: flex;
  align-items: center;
  background-color: #ffffff;
  height: 35px;
  border-radius: 5px;
  border: 1px solid #e0e3eb;
  box-sizing: content-box;
}

.dropdown-btn {
  outline: none;
  background-color: #f9f9fb;
  border: #e0e3eb;
  padding: 6.5px 10px;
  border-left: 1px solid #e0e3eb;
  border-top-right-radius: 12%;
  border-bottom-right-radius: 12%;
  border-top-left-radius: 0%;
  border-bottom-left-radius: 0%;
}
.dropdown-btn:focus,
.dropdown-btn:hover {
  outline: none;
  border-left: 1px solid #e0e3eb;
}
.dropdown-icon {
  color: #a3aac2;
}

.input-container input {
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 131.023%;
  height: 100%;
  border: none;
  background-color: transparent;
  padding-left: 12px;
}
.input-container input::placeholder {
  color: #cdcdcd;
  font-family: "Inter", sans-serif;
}
.input-container input:focus {
  outline: none;
}

.network-details-btn {
  background: transparent;
  padding: 0;
  margin: 0;
  border: 1px solid #e0e3eb;
  outline: none;
  // height: 35px;
  width: 35px;
  margin-right: 10px;
}

.network-details-btn:focus,
.network-details-btn:active,
.network-details-btn:hover {
  border: 1px solid #e0e3eb;
  outline: none;
}
.network-details-btn .tooltiptext {
  visibility: hidden;
  width: 125px;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 6px 3px;
  position: absolute;
  z-index: 1;
  top: -7%;
  font-size: 12px;
  right: 0%;
  opacity: 0;
  transition: opacity 0.3s;
  font-family: "Inter", sans-serif;
}
.network-details-btn:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}
.badge {
  background-color: #f87171;
  color: white;
  position: absolute;
  height: 12px;
  width: 12px;
  top: 4px;
  right: 4px;
  font-size: 5px;
  padding: 4px;
  border-radius: 50%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}
.node-tooltip {
  position: absolute;
  background-color: #ffffff;
  z-index: 3000;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  border-radius: 5px;
  padding-left: 0px;
  margin: 0px;
  padding: 3px;
  width: 60px;
}

.node-tooltip button {
  border: none;
  outline: none;
  background-color: transparent;
  color: #000;
  font-family: IBM Plex Sans;
  font-size: 8px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin: 0px;
  padding: 4px;
}
.network-graph {
  height: 93%;
  position: relative;
  border: none !important;
}

.node-tooltip button:active {
  border: none;
  outline: none;
}

.vis-network {
  background-color: #fcfcfc !important;
  background-image: radial-gradient(
    circle at center,
    #a4a4a4 1px,
    transparent 1px
  );
  background-size: 80px 80px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  padding-top: 18px;
  padding-bottom: 16px;
}

.vis-button.vis-up,
.vis-button.vis-down,
.vis-button.vis-left,
.vis-button.vis-right {
  display: none !important;
}

.vis-button {
  background-size: contain;
  height: 10px !important;
  width: 10px !important;
  padding: 15px;
  border-radius: 4px !important;
  background: url("../../assets/plusIcon.svg") no-repeat scroll center !important;
}
.vis-button:hover {
  box-shadow: none !important;
}
.vis-button.vis-zoomIn {
  background: url("../../assets/plusIcon.svg") no-repeat scroll center !important;
  left: 15px !important;
  bottom: 90px !important;
}
.vis-zoomOut {
  background: url("../../assets/minusIcon.svg") no-repeat scroll center !important;
  left: 15px !important;
  bottom: 50px !important;
}

.vis-zoomExtends {
  background: url("../../assets/fitIcon.svg") no-repeat scroll center !important;
  left: 15px !important;
  bottom: 10px !important;
}
