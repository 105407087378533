.tooltip-container {
  display: inline-block;
  position: relative;
}

.tooltip-box {
  position: absolute;
  background-color: #4d4d4d;
  color: #fff;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 10px;
  white-space: nowrap;
  z-index: 10;
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip-container:hover .tooltip-box {
  opacity: 1;
}

.tooltip-box.top {
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  margin-bottom: 5px;
}
