.approval-comment-container {
  display: flex;
  font-family: Inter;
  gap: 15px;
  margin-bottom: 10px;
  color: #3d445c;
  padding: 0px 10px 6px 15px;
  border-bottom: 1px solid #eaeaea;
  margin-top: 15px;
  margin-bottom: 15px;
}

.approval-comment-avatar {
  height: 35px;
  width: 40px;
  border-radius: 50%;
  background-color: #d9d9d9;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  font-weight: bold;
}
.approval-comment {
  width: 100%;
}
.approval-comment-header {
  display: flex;
  align-items: center;
  gap: 12px;
  justify-content: space-between;
}
.approval-comment-username {
  font-size: 12px;
  font-weight: 600;
  color: #3d445c;
  margin: 0px;
}
.approval-comment-status {
  height: 5px;
  width: 5px;
  border-radius: 50%;
  background: black;
  margin: 0px 0px 0px -4px;
}
.approval-comment-timeago {
  font-size: 11px;
  color: #a3aac2;
  font-weight: 400;
}
.approval-comment-level {
  background-color: #e8f3fd;
  color: #5b80f7;
  font-family: Inter;
  font-size: 11px;
  font-weight: bold;
  padding: 1px 6px;
  border-radius: 5px;
}
.approval-comment-action {
  font-family: Inter;
  font-size: 11px;
  font-weight: 500;
  padding: 1px 6px;
  border-radius: 5px;
}
.approval-comment-text {
  width: 100%;
  color: #525b7a;
  font-size: 12px;
  font-family: Inter;
  font-weight: 500;
}
.approval-comment-textarea:active,
.approval-comment-textarea:focus-visible,
.approval-comment-textarea:focus {
  border: 1px solid #e0e3eb;
  outline: none;
}
.approval-status-approved {
  background-color: #6abe77;
  color: white;
}
.approval-status-rejected {
  background-color: #ff6a6a;
  color: white;
}
.approval-status-submit {
  background-color: #525b7a;
  color: #ffffff;
}
