.table-cell-align {
  padding-left: 16px;
  padding-right: 16px;
}
.action-view-btn-box {
  display: flex;
  justify-content: center;
}
.action-view-btn {
  border: none;
  background-color: #d1d1d1;
  border-radius: 3px;
  color: #454d5b;
  font-family: Inter;
  font-size: 12px;
  padding: 2px 12px;
}
.status-box {
  display: flex;
  gap: 9px;
  align-items: center;
  padding: 0px 8px;
}
.status-circle {
  height: 9px;
  width: 9px;
  border-radius: 50%;
  background-color: #70d880;
}
.mps {
  text-decoration: underline;
  color: #2f6df6;
}
.action-view-btn:hover {
  background-color: #f9652b;
  color: white;
}
.pp-level-btns { 
  background-color: #EDEDED;
  border-radius: 5px;
  /* padding: 4px; */
  font-size: 12px;
  font-family: IBM Plex Sans;
  font-weight: bold;
  margin-top: auto;
  margin-bottom: auto;
}
.pp-level-button {
  color: #4E4E4E;
  padding: 2px 10px;
  margin: 2px;
}
.pp-active-btn {
  background-color: #ffffff;
  color: #e94f1b;
  border-radius: 3px;
}
.pp-add-new{
  background-color: #e55c2e;
}