.legend-container {
  display: flex;
  margin-top: 10px;
}

.legend-heading {
  margin-right: 8px;
  font-weight: 450;
  border: 1px solid black;
}

.legend-item {
  display: flex;
  align-items: center;
  margin-right: 15px;
}

.legend-color {
  display: inline-block;
  width: 6px;
  height: 6px;
  margin-right: 5px;
}

.legend-label {
  color: #666;
  font-family: IBM Plex Sans;
  font-size: 11px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-left: 8px;
}

.stakeholder {
  margin-top: 10px;
  display: flex;
  justify-content: center;
  font-family: "Roboto";
}
