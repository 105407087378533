.calendar-icon-layout {
  width: 30px;
  border-radius: 4px;
  box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.1);
  cursor: pointer;
}
.calendar-icon-month {
  background: #e94f1b;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  color: white;
  padding: 2px;
  text-align: center;
  font-size: 9px;
  font-family: Inter;
  font-weight: 400;
  line-height: 11.1px;
  padding-bottom: 0px;
  padding-top: 1px;
}
.calendar-icon-day {
  background: white;
  color: #e94f1b;
  text-align: center;
  font-size: 12px;
  line-height: 14.73px;
  font-family: Inter;
  font-weight: 400;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  padding-top: 2px;
}
