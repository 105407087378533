.orders-mgmt-header-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 16px;
}

.orders-mgmt-btns-box {
  border-radius: 4px;
  background-color: #686867;
  padding: 1px;
  display: flex;
  align-items: center;
}

.orders-mgmt-btn {
  color: #ffffff;
  background-color: #686867;
  font-family: IBM Plex Sans;
  padding: 1px 15px;
  font-size: 14px;
  font-weight: 600;
  border-radius: 3px;
}
.orders-mgmt-btn:hover {
  background-color: #434341;
}
.orders-mgmt-active-btn,
.orders-mgmt-active-btn:hover {
  background-color: #ffffff;
  color: #e94f1c;
}
.orders-mgmt-rp {
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}
.orders-mgmt-pp {
  border-radius: 0px;
}
.orders-mgmt-mrp {
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}
.orders-mgmt-action-btn {
  border: 1px solid #454d5b;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 600;
  color: #454d5b;
  padding: 3px 12px;
  align-self: center;
}
.orders-mgmt-action-btn-upload {
  padding: 0px;
  height: 24px;
  width: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.orders-mgmt-action-btn:disabled {
  color: #d2d2d1;
  border: 1px solid #c5cad3;
  background-color: #f4f4f4;;
}
.orders-mgmt-action-btn:not([disabled]):hover {
  background-color: #686867;
  color: #ffffff;
}
.orders-mgmt-action-btn:not([disabled]):hover path {
  fill: #ffffff;
}
