.common-props {
  font-family: Inter;
  width: calc(100% - 20px);
  margin: 10px;
}

.single-select-dd {
  width: 40%;
  // margin-top: 20px;
  margin-bottom: 5px;
  text-align: left;
}

.single-select-container {
  display: flex;
  justify-content: space-between;
  height: 80px;
}

.dd-lable {
  display: flex;
  color: #525b7a;
  font-size: 13px;
  font-family: Inter;
  font-weight: bold;
  line-height: 20px;
  word-wrap: break-word;
}

.cron-container {
  height: 30vh;
  margin-top: 5px;
  margin-left: 0;
}

.switch {
  position: relative;
  display: inline-block;
  width: 30px;
  height: 15px;
  margin-left: 10px;
  top: 1px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-color: #e4e4e4;
}

.slider:before {
  position: absolute;
  left: 0;
  content: "";
  height: 15px;
  width: 15px;
  background-color: white;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

input:checked + .slider {
  background-color: #5b80f7;
}

input:focus + .slider {
  box-shadow: 0 0 1px #5b80f7;
}

input:checked + .slider:before {
  -webkit-transform: translateX(15px);
  -ms-transform: translateX(15px);
  transform: translateX(15px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 26px;
  border: 1px solid #e4e4e4;
}

.slider.round:before {
  border-radius: 50%;
  box-shadow: 0px 0px 1px 1px #e4e4e4;
}

.schedular-header {
  font-size: 16px;
  font-family: Inter;
  font-weight: 500;
  line-height: 24px;
  word-wrap: break-word;
  // margin-top: 25px;
  text-align: left;
  
}

.schedular-subheader {
  color: #666;
  font-size: 12px;
  font-family: Inter;
  font-weight: bold;
  line-height: 18.34px;
  word-wrap: break-word;
  text-align: left;
  margin-left: 5px;
}

.react-js-cron-clear-button {
  background-color: #ec7676 !important;
}

.react-datepicker-popper {
  z-index: 100;
}
.example-custom-input {
  padding-left: 35px;
  border: 0.5px #d2d2d2 solid;
  border-radius: 5px;
  box-sizing: unset;
}
.cancel {
  color: #4b4b4b;
  border: 1px solid #3d445c;
  background-color: #ffffff;
}

.save {
  color: white;
  background-color: #3d445c;
}

.run-adhoc-job {
  width: auto !important;
  color: white;
  background-color: #029f00;
  padding-left: 9px;
  padding-right: 9px;
  padding-top: 0;
  padding-bottom: 0;
}
.action-button {
  font-size: 13px;
  font-family: Inter;
  font-weight: 400;
  line-height: 17.03px;
  word-wrap: break-word;
  width: 120px;
  height: 37px;
  border-radius: 5px;
}

.job-name-input {
  font-family: "Roboto", sans-serif;
  color: #333;
  font-size: 12px;
  margin: 0 auto;
  border-radius: 5px;
  height: 38px;
  background-color: rgb(255, 255, 255);
  width: 100%;
  transition: all 0.3s;
  border: 2px #e0e3eb solid;
  padding: 2px 8px;
  box-sizing: border-box;
}

.job-name-input:hover {
  border-color: hsl(0, 0%, 70%);
}

.job-name-input:focus-visible {
  outline: 2px solid #2684ff !important;
}
.select__control {
  border: 0.5px #e0e3eb solid;
}

.custom-select > div {
  border: 2px #e0e3eb solid;
  border-radius: 5px;
  color: black;
  font-size: 12px;
  font-family: Inter;
  font-weight: 400;
  word-wrap: break-word;
}

.ant-select-selection-overflow-item > span > div,
.ant-select-selector > span {
  color: #515151 !important;
  font-size: 14px !important;
  font-family: Inter !important;
  font-weight: 500 !important;
  // line-height: 18.34px !important;
  word-wrap: break-word !important;
}

.react-js-cron-field > span {
  color: black;
  font-size: 14px;
  font-family: Inter;
  font-weight: 500;
  line-height: 18.34px;
  word-wrap: break-word;
  margin-left: 10px;
  // margin-right: 10px;
}

.react-js-cron-clear-button,
.react-js-cron-clear-button > span {
  background-color: #ec7676 !important;
  color: #3d445c;
  font-size: 14px;
  font-family: Inter;
  font-weight: 500;
  line-height: 18.34px;
  word-wrap: break-word;
  border-radius: 5px;
}

.react-js-cron {
  display: flex;
  margin-top: 20px;
  margin-bottom: 3px;
}

.react-js-cron > div:last-child {
  display: contents !important;
}

div.react-js-cron-field.react-js-cron-week-days > div > div {
  width: 190px;
  margin-left: 10px;
}

div.react-js-cron-field.react-js-cron-hours > div > div {
  width: 120px;
  margin-left: 10px;
}
div.react-js-cron-field.react-js-cron-minutes > div {
  width: 140px;
  margin-left: 10px;
}

.react-js-cron-clear-button {
  margin-left: 30px;
}

div.react-js-cron-field.react-js-cron-period > span {
  margin-left: 0px;
  margin-right: 10px;
}

div.react-js-cron-field.react-js-cron-months > div > div {
  width: 124px;
  margin-left: 10px;
}

div.react-js-cron-field.react-js-cron-month-days > div > div {
  width: 200px;
  margin-left: 10px;
}
div.common-props
  > div
  > div:nth-child(6)
  > div
  > div
  > div:nth-child(2)
  > div
  > div:last-child {
  display: flex;
}

.prompt-modal {
  background-color: white;
  position: absolute;
  margin: auto;
  width: 40%;
  display: flex;
  flex-direction: column;
  padding: 12px;
  border-radius: 12px;
}
.scheduler-cancel-button {
  font-size: 12px;
  border: 1px solid #e2e8f0;
  background-color: #ffffff;
  padding: 6px 18px;
}
.scheduler-yes-button {
  font-size: 12px;
  margin-left: 12px;
  border: none;
  background-color: #1f222e;
  color: #ffffff;
  padding: 6px 18px;
}
.js-error {
  color: red;
  font-family: Inter;
  font-size: 10px;
  min-height: 15px;
}
