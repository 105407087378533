@import "../../partials/styles.scss";

.node-details {
  position: absolute;
  background: #ffffff;
  width: 0;
  right: 0;
  z-index: 1000;
  transition: all 0.5s ease;
  padding-top: 16px;
  padding-bottom: 40px;
  box-shadow: 0px 10px 35px -10px rgba(0, 0, 0, 0.2);
  overflow-y: scroll;
  height: 100%;
  transform: translateX(100%); /* Start position outside the viewport */
  display: none;
  // transition: transform 0.3s ease;;
}

.toggle-bar {
  margin-top: -12px;
  width: 34%;
  right: 0px;
  transform: translateX(0);
  display: block;
}
.toggle-filters-bar {
  width: 28%;
  right: 0px;
}
.node-details-header {
  display: flex;
  align-items: center;
  padding: 0px 10px;
  justify-content: space-between;
}

.node-details-header h1 {
  color: #414141;
  font-family: "Inter", sans-serif;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.close-btn {
  background-color: white;
  margin: 0;
  padding: 0;
  text-align: center;
  height: 32px;
  width: 32px;
  border-radius: 32px;
  border: 1px solid #d9d9d9;
}
.close-btn:focus,
.close-btn:active,
.close-btn:hover {
  outline: none;
  border: 1px solid #d9d9d9;
}
.node-details-line {
  border: 1px solid #e7e7e7;
  margin-top: 14px;
}
.table {
  width: 100%;
  border-collapse: collapse;
}
.table tbody {
  display: table;
  width: 100%;
}
.table td {
  border: none;
  font-family: 13px;
  font-family: "Inter", sans-serif;
  color: #555;
  font-weight: 400;
}
tr {
  padding: 5px 8px;
}
.table td {
  position: relative;
}
tr td:first-of-type {
  // padding-left: 10px;
    // width: 73%;
}
.table td::before {
  content: "";
  position: absolute;
  top: 0;
  left: -1px;
  height: 100%;
  width: 1px;
  background-color: transparent;
}

.table td:first-child::before {
  display: none;
}
.table tr:hover {
  background: #edf1f2;
}
