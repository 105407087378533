.react-autosuggest__container {
  background-color: #ffffff;
  position: relative;
  flex-grow: 1;
  border-radius: 5px;
  width: 100%;
  box-sizing: border-box;
}

.react-autosuggest__input,
.input-container {
  width: 100%;
  height: 100%;
  border: 1px solid #e0e3eb;
  padding: 10px 14px !important;
  border-radius: 5px;
  font-family: Inter !important;
  font-size: 13px !important;
  color: #858686 !important;
  font-weight: 500;
}
.react-autosuggest__input::placeholder {
  color: #acb2ba;
  font-size: 13px;
  font-family: Inter !important;
}
.react-autosuggest__input--focused {
  outline: none;
}
.react-autosuggest__input--open {
  outline: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  padding: 10px 14px;
}

.react-autosuggest__suggestions-container {
  display: none;
}

.react-autosuggest__suggestions-container--open {
  display: block;
  position: absolute;
  /* top: 35px; */
  width: 100%;
  border: 1px solid #e0e3eb;
  max-height: 200px !important;
  overflow-y: auto;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  z-index: 9999;
  /* border-top: none; */
  padding-left: 9px;
  padding-right: 9px;
  padding-top: 4px;
  padding-bottom: 9px;
  background-color: white;
}

.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
  text-align: start;
  z-index: 9999;
}
.react-autosuggest__suggestions-container::-webkit-scrollbar {
  width: 7px;
  background-color: none;
}

.react-autosuggest__suggestions-container::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

.react-autosuggest__suggestion {
  padding-top: 6px !important;
  padding-bottom: 6px !important;
  z-index: 9999;
  color: #858686;
  font-family: Inter !important;
  cursor: pointer;
  font-size: 13px !important;
  font-weight: 500;
}

.add-sto-input-box {
  display: flex;
  flex-direction: column;
  width: 24%;
}
.add-sto-input-label {
  font-family: Inter;
  font-size: 13px;
  font-weight: 599;
  line-height: 26px;
  color: #525b7a;
  letter-spacing: 0em;
}
.add-sto-select-box {
  border: 1px solid #e0e3eb;
  border-radius: 5px;
  padding: 11px 12px;
  color: #acb2ba;
  font-family: Inter;
}
.add-sto-select-box::placeholder {
  color: #acb2ba !important;
  line-height: 15.73px;
}
.add-sto-select-box option {
  font-size: 13px;
  font-weight: 400;
  padding: 4px;
}

.add-sto-select-box:active,
.add-sto-select-box:focus {
  outline: none;
}

.add-sto-input-text {
  font-size: 13px;
  font-weight: 400;
  line-height: 15.73px;
  padding: 10px 12px;
  color: #858686 !important;
}
.error {
  color: red;
  font-size: 8px;
  font-family: Inter;
  padding-left: 5px;
  min-height: 12px;
}
.add-sto-input-text::placeholder {
  color: #acb2ba;
  font-size: 13px;
  font-family: Inter !important;
}
.job-scheduler-date {
  width: 24% !important;
}
.js-date-input {
  color: #1c1c1c !important;
}
