.snp-table-container
  table
  thead
  th
  div[class~="Mui-TableHeadCell-Content-Actions"] {
  border-right: 1.5px solid #e0e3eb;
}
.snp-table-container
  div[class~="MuiPaper-root"]
  div[class~="MuiToolbar-root"]
  div[class~="MuiBox-root"]
  div[class~="MuiBox-root"]
  div[class~="MuiBox-root"]
  div[class~="MuiBox-root"]
  div[class~="MuiFormControl-root"]
  div[class~="MuiInputBase-root"] {
  height: 25px !important;
}

.snp-table-container
  > div[class~="MuiPaper-root"]
  > div[class~="MuiToolbar-root"]
  > div[class~="MuiBox-root"]
  > div[class~="MuiBox-root"]
  > div[class~="MuiCollapse-root"]
  div[class~="MuiFormControl-root"]
  div[class~="MuiInputBase-root"] {
  display: none;
}
// .snp-table-container .MuiInputBase-root input {
//   padding: 0;
//   height: 25px;
// }

// .snp-table-container .MuiInputBase-root fieldset {
//   border: 1.5px solid #EFEFEF !important;
// }

// .snp-table-container .MuiInputBase-root svg {
//   margin-right: 5px;
// }

// .snp-table-container .MuiFormControl-root {
//   position: absolute;
//   top: 21%;
//   right: 100%;
// }

// .snp-table-container .MuiCollapse-wrapperInner {
//   position: relative;
// }
.snp-table-container .MuiToolbar-root {
  background-color: white !important;
  font-family: Inter;
  color: #3d445c;
  font-size: 13px;
}

.snp-table-container .MuiToolbar-root p {
  background-color: white !important;
  font-family: Inter;
  color: #3d445c;
  font-size: 13px;
}

.snp-table-container .MuiTablePagination-toolbar .MuiInputBase-root {
  border-radius: 4px;
  border: 1px solid #d7d7d7;
  background: #fff;
}

.snp-table-container .MuiTableContainer-root table {
  margin-top: 0;
}

.snp-table-container .MuiAutocomplete-clearIndicator {
  display: none;
}

.snp-table-container .MuiTableContainer-root {
  border: 1.5px solid #e0e3eb;
  padding-bottom: 12px;
  // min-height: 400px;
  // height: 400px;
  max-height: 480px;
}

.mini-table .MuiTableContainer-root {
  padding-bottom: unset;
  min-height: unset;
}
// .snp-table-container .MuiTableContainer-root::-webkit-scrollbar {
//   background-color: none;
//   width: 5px;
// }

.snp-table-container .MuiTableContainer-root::-webkit-scrollbar-thumb {
  background-color: grey;
  border-top: 1px solid white;
  // border-top: 1px solid grey;
}

.snp-table-container .MuiTableCell-body {
  border-bottom: 0.5px !important;
}

/* needed */
.Mui-TableHeadCell-Content {
  display: flex !important;
  justify-content: space-between !important;
  padding: 0 !important;
}
div.MuiBox-root.css-sq9qdz {
  padding: 0.2em !important;
}

/* needed */
.disabled-cell {
  color: #b2b7cc;
  font-size: 12px;
  font-family: Inter;
  font-weight: 400;
  line-height: 15.72px;
  word-wrap: break-word;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* needed */

.active-cell {
  color: #525b7a;
  font-size: 12px;
  font-family: Inter;
  font-weight: 400;
  line-height: 15.72px;
  word-wrap: break-word;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  padding-right: 10px;
}

.td {
  border: 0 !important;
}

.snp-table-container .MuiTableCell-head {
  background-color: #a3aac2 !important;
  border: 0;
}

.snp-table-container .MuiTableRow-head > th {
  height: 36px;
  background-color: #d1d1d1 !important;
  font-size: 13px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-weight: 600;
  line-height: 36px;
  word-wrap: break-word;
  box-shadow: none;
  padding: 0 5px;
  // border-right: 1.5px solid #e0e3eb;
  border-bottom: 1.5px solid #e0e3eb;
}
.snp-table-container .MuiTableRow-head > th:last-child {
  border-right: none;
}
.snp-table-container .MuiPopover-root > .MuiMenu-paper > ul > li {
  font-size: 13px;
  font-family: Inter;
  font-weight: 500;
  line-height: 30.03px;
  word-wrap: break-word;
  height: 32px;
}

.table-action-button {
  padding: 1.5px 10px;
  background-color: unset;
}

button.table-action-button:hover {
  border: 1px solid transparent;
}

button.table-action-button:focus {
  outline: unset;
}

.edit-button:hover {
  background-color: #3d445c;
}

.delete-button:hover {
  background-color: #ea7a7a;
}

.config-button:hover {
  background-color: #3d445c;
}

.setting-button:hover {
  background-color: #3d445c;
}

.action-icon-hover:hover path {
  fill: white;
}

.table-action-button:hover path {
  fill: white;
}

.snp-table-container .MuiTableCell-root {
  border-right: none;
}

.snp-table-container .MuiPaper-root {
  box-shadow: unset;
}

.snp-table-container table tbody tr td {
  padding: 6px 14px 6px 12px;
  box-shadow: none;
  border-left: none;
  border-right: none;
}

.create-new-button {
  background-color: #3d445c;
  color: white;
  height: 32px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
}
.create-new-button svg {
  flex-grow: 1;
  margin-right: 12px;
}
.upload-button {
  display: flex;
  justify-content: center;
  align-items: center;
}
.upload-button:hover path {
  color: #000000;
  fill: #000000;
}
.disabled-btn path {
  opacity: 0.2;
}
.view-button-preprocess {
  border: 1px solid #e0e3eb;
  border-radius: 4px;
  color: #3d445c;
  font-family: Inter;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  padding: 0px 10px;
  margin-left: 10px;
}
.view-button-preprocess:hover,
.view-button-preprocess:active,
.view-button-preprocess:focus {
  border: none;
  background-color: #3d445c;
  color: white;
}
.view-button-preprocess-disabled {
  color: #d9d9d9;
}
.view-button-preprocess-disabled:hover {
  opacity: 0.2;
}
.disabled {
  opacity: 0.1;
}
.level-btns {
  background-color: #EDEDED;
  border-radius: 5px;
  /* padding: 4px; */
  font-size: 12px;
  font-family: IBM Plex Sans;
  font-weight: bold;
  margin-top: auto;
  margin-bottom: auto;
  width: 211px;
}
.level-btns > button {
  margin-right: 5px;
}
.level-button {
  color: #4E4E4E;
  padding: 2px 10px;
  margin: 2px;
}
.active-btn {
  background-color: #ffffff;
  color: #e94f1b;
  border-radius: 3px;
}
.view-button-org {
  border: 1px solid #a3aac2;
  border-radius: 4px;
  color: #3d445c;
  font-family: Inter;
  font-weight: 600;
  font-size: 11px;
  line-height: 18px;
  padding: 1px 10px;
  margin-left: 10px;
}
.view-button-org:hover {
  border: 1px solid #a3aac2 !important;
}
.status-col {
  display: inline-flex;
  align-items: center;
  gap: 20px;
}
.status-indicator {
  border-radius: 50%;
  background-color: #00ca14;
  height: 8px;
  width: 8px;
}

.plan-status {
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  gap: 60px;
}
.info-icon {
  color: white;
  background-color: #a3aac2;
  border-radius: 50%;
  // opacity: 0.2;
}
.status-indicator-app {
  border-radius: 50%;
  background-color: #00ca14;
  height: 10px;
  width: 10px;
}
.grp-action-live-btn {
  box-sizing: border-box;
    height: 30px;
  border: none;
  outline: none;
  background-color: #e0e3eb;
  border-radius: 5px;
  color: white;
  padding: 0px 10px;
    font-size: 13px;
  // margin-left: auto !important;
}
.grp-action-live-enable {
  background-color: #ffffff;
  border: 1px solid #d9d9d9;
  color: #3d445c;
}
.grp-action-live-enable:hover {
  background-color: #3d445c;
  color: white;
}
.live-btn-enable {
  background-color: #ffffff;
  border: 1px solid #029f00;
  color: #029f00;
  cursor: default;
}
.grp-action-live-btn-view {
  margin: unset !important;
}
.send-revoke-box {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.send-revoke-input {
  opacity: 0;
  width: 0;
  height: 0;
}

.send-revoke-slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.send-revoke-slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.send-revoke-input:checked + .send-revoke-slider {
  background-color: #029f00;
}

.send-revoke-input:focus + .send-revoke-slider {
  box-shadow: 0 0 1px #029f00;
}

.send-revoke-input:checked + .send-revoke-slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(17px);
}

.send-revoke-slider.send-revoke-round {
  border-radius: 34px;
}

.send-revoke-slider.send-revoke-round:before {
  border-radius: 50%;
}

.small-switch {
  width: 34px;
  height: 17px;
}

.small-switch .send-revoke-slider {
  width: 34px;
  height: 17px;
}

.small-switch .send-revoke-slider:before {
  width: 15px;
  height: 15px;
  left: 1px;
  bottom: 1px;
}
.table-left-bottom {
  display: flex;
  width: 55%;
}

.table-left-bottom-footer p {
  font-size: 12px !important;
  line-height: 19px;
  font-family: Inter;
  font-weight: 400;
  color: #3d445c;
  margin-bottom: unset;
}
.action-buttons {
  display: flex;
  align-items: center;
  gap: 15px;
  margin-top: 0px;
  margin-bottom: 0px;
}
.action-buttons-align {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
}
.download-button {
  height: 30px;
  padding: 10px;
  border: 1px solid #e0e3eb;
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
}
.Mui-TableHeadCell-Content-Wrapper {
  white-space: nowrap !important;
}

.Mui-TableHeadCell-Content-Wrapper:hover {
  white-space: nowrap !important;
  text-overflow: ellipsis !important;
}

.Mui-TableHeadCell-ResizeHandle-Wrapper {
  opacity: 0.7;
}

.Mui-TableHeadCell-Content-Labels {
  padding-left: 6px;
}

.MuiTableHead-root {
  // z-index: 1 !important;
}
// div.snp-table-container
//   > div
//   > div.MuiDialog-container.MuiDialog-scrollPaper
//   > div
//   > div {
//   margin-left: 225px;
// }

.MuiDialog-paperFullScreen {
  margin-left: 225px;
}

div.snp-table-container
  > div
  > div.MuiDialog-container.MuiDialog-scrollPaper
  > div
  > div {
  margin-top: 200px;
}
// div.snp-table-container
//   > div
//   > div.MuiDialog-container.MuiDialog-scrollPaper
//   > div
//   > div {
//   margin-left: 240px !important;
//   margin-top: 60px !important;
//   height: calc(100vh - 60px) !important;
//   width: calc(100vw - 240px) !important;
// }

.snp-table-container .MuiTableRow-root.Mui-selected td {
  background-color: #ddeaf8;
}
// div.snp-table-container
//   > div
//   > div.MuiDialog-container.MuiDialog-scrollPaper
//   > div
//   > div
//   > div.MuiToolbar-root.MuiToolbar-gutters
//   > div {
//   width: calc(100vw - 240px) !important;
//   margin-left: 240px !important;
// }
// .MuiTooltip-tooltip {
//   font-size: 10px !important;
// }

div.snp-table-container
  > div
  > div.MuiTableContainer-root
  > table
  > thead
  > tr
  > th.MuiTableCell-root.MuiTableCell-head.MuiTableCell-stickyHeader.MuiTableCell-alignLeft.MuiTableCell-sizeMedium.css-1swfepf-MuiTableCell-root
  > div.MuiCollapse-root.MuiCollapse-vertical.MuiCollapse-entered
  > div
  > div
  > div
  > div {
  display: none;
}
.snp-table-container .Mui-TableHeadCell-Content-Labels {
  width: 100%;
  display: flex;
  padding-right: 2px;
}

// div.snp-table-container
//   > div
//   > div.MuiDialog-container.MuiDialog-scrollPaper
//   > div
//   > div
//   > div.MuiToolbar-root.MuiToolbar-gutters.MuiToolbar-dense {
//   width: calc(100vw - 240px) !important;
//   margin-left: 240px !important;
// }
.snp-table-container .MuiTableHead-root th:nth-child(1):has(.MuiCheckbox-root),
.snp-table-container .MuiTableRow-root td:nth-child(1):has(.MuiCheckbox-root) {
  position: sticky !important;
  left: 0;
  z-index: 1 !important;
  width: 30px;
}
.snp-table-container .MuiTableHead-root th:nth-child(1):has(.MuiCheckbox-root) {
  z-index: 2 !important;
}
.snp-table-container .MuiTableHead-root th:nth-child(1) span svg {
  height: 20px;
  width: 20px;
}
.snp-table-container .MuiTableHead-root th > div > div > span:nth-child(2) {
  margin-left: auto;
}
.snp-table-container .MuiTableRow-root td:nth-child(1) > span > svg {
  height: 20px;
  width: 20px;
}
.snp-table-container
  .MuiTableRow-root
  td:nth-child(1)
  > span[class*="MuiCheckbox-root"],
.snp-table-container
  .MuiTableHead-root
  th:nth-child(1)
  > span[class*="MuiCheckbox-root"] {
  height: 20px !important;
  width: 20px !important;
}
.snp-table-container .MuiTableHead-root th:nth-child(1) span span svg {
  height: 1em;
  width: 1em;
}
.snp-table-container .MuiTableHead-root th span span svg {
  height: 1em;
  width: 1em;
}
.snp-table-container .MuiTableHead-root th span span svg {
  opacity: 0.6 !important;
}
.snp-table-container .MuiTableHead-root th span span svg path {
  fill: rgba(0, 0, 0, 0.87);
}

// .Mui-TableHeadCell-Content-Actions {
//   display: none;
// }
// .MuiTableHead-root
//   .MuiTableRow-root
//   .Mui-TableHeadCell-Content-Actions:last-child {
//   display: none !important;
// }

.MuiTableHead-root .MuiTableRow-root > th:last-child > div > div:nth-child(2) {
  display: none;
}
.snp-table-container
  div[class~="Mui-TableHeadCell-ResizeHandle-Wrapper"]
  hr[class~="MuiDivider-vertical"] {
  height: 30px !important;
}
.snp-table-container table tbody tr:has(.sto-manually-created),
// .snp-table-container table tbody tr:has(.sto-manually-created):hover td,
.snp-table-container table tbody td:has(.sto-manually-created) {
  background-color: #c6f4f5 !important;
}
.snp-table-container table tbody tr:has(.sto-manually-created):hover td,
.snp-table-container table tbody tr:hover td:has(.sto-manually-created) {
  background-color: #a8eff0 !important;
}

.snp-table-container table tbody tr:has(.sto-edited),
// .snp-table-container table tbody tr:has(.sto-edited):hover td,
.snp-table-container table tbody td:has(.sto-edited) {
  background-color: #c6d4ee !important;
}

.snp-table-container table tbody tr:has(.sto-edited):hover td,
.snp-table-container table tbody tr:hover td:has(.sto-edited) {
  background-color: #b0c4e8 !important;
}

.snp-table-container table .Mui-TableHeadCell-Content-Wrapper {
  font-weight: 600;
  color: black;
    font-size: 14px;
}
@media (min-width: 1600px) {
  .snp-table-container .MuiTableContainer-root {
    max-height: 650px;
  }

  .active-cell {
    font-size: 15px;
  }

  .snp-table-container table tbody tr td {
    padding: 8px 14px 8px 12px;
  }

  .snp-table-container table .Mui-TableHeadCell-Content-Wrapper {
    font-size: 15px;
  }
}
.process-download-btn {
  height: 26px;
  padding: 8px;
  padding-top: 9px;
  padding-bottom: 9px;
}
