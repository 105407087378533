.status-indicator {
    border-radius: 50%;
    height: 10px;
    width: 10px;
  }
  .progress {
    border: 1px solid #00ca14;
    background-color: white;
  }
  .completed {
    background-color: #00ca14;
  }
  
  .rejected {
    background-color: #ff5555;
  }
  .info-button:hover {
    border-radius: 50% !important;
    background-color: red;
  }
  .group-plan-action-button {
    /* background: transparent; */
    padding: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 22px;
    width: 22px;
  }
  .group-plan-action-button:hover {
    /* background-color: #ff5555; */
    padding: 5px;
    border-radius: 5px;
  }
  .remove:hover {
    background-color: #ea7a7a;
  }
  .save:hover {
    background-color: #8cce81;
  }
  
  .group-plan-action-button:hover path {
    fill: white;
  }
  .group-plan-action-button:disabled, .group-plan-action-button:disabled:hover {
    background-color: #f4f4f4;
  }
  .group-plan-action-button:disabled:hover path {
    fill: #d2d2d1;
  }
  .sto-card {
    box-sizing: border-box;
    height: 30px;
    background-color: #e8f3fd;
    border: none;
    align-items: center;
    display: flex;
    gap: 18px;
    padding: 5px 10px;
    border-radius: 4px;
    background-color: #f9f9fb;
    border: 1px solid #e0e3eb;
    cursor: pointer;
  }
  .sto-card-active {
    background-color: #e8f3fd;
    border: none;
  }
  .sto-card span {
    background-color: unset !important;
  }
  .sto-card span:nth-child(2) {
    font-weight: 600;
  }
  .sto-card-heading {
    font-size: 12px;
    font-weight: 400;
  }
  .sto-view-cancel-button {
    border: 1px solid #e2e8f0;
    background-color: #ffffff;
    padding: 5px 10px;
  }
  .sto-view-save-button {
    margin-left: 12px;
    border: none;
    background-color: #1f222e;
    color: #ffffff;
    padding: 5px 10px;
  }
  .sto-view-save-disabled {
    opacity: 0.3;
  }
  .editable-input {
    border: 1.5px solid #67dbff;
    text-align: center;
    color: #4b4b4b;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    /* line-height: 131.023%; */
  }
  .editable-input:focus {
    outline: none;
    border: 1.5px solid #67dbff;
  }
  .edit-mode-buttons {
    display: flex;
    gap: 6px;
  }
  .edit-mode-buttons button {
    background-color: #e3e3e3;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 4px;
    border-radius: 0px;
  }
  
  .inv-mgmt-link {
    font-family: Inter;
    font-weight: 400;
    font-size: 13px;
    line-height: 15.73px;
    color: #3d445c;
    cursor: pointer;
  }
  
  .inv-stats-text {
    font-family: Inter;
    font-size: 13px;
    line-height: 15.73px;
    font-weight: 400;
    color: #777777;
  }
  
  .inv-mgmt-link:hover {
    color: #2f6df0;
    text-decoration: underline;
    text-underline-position: under;
  }
  .allocation-quantity {
    margin: unset;
  }
  .sto-multi-accept:hover {
    background-color: #8cce81;
  }
  .sto-multi-accept:hover path {
    fill: white;
  }
  .sto-multi-reject:hover {
    background-color: #ea7a7a;
  }
  .sto-multi-reject:hover path {
    fill: white;
  }

  .sto-multi-accept:disabled path,
.sto-multi-reject:disabled path {
  fill: #d2d2d1;
}
.sto-multi-accept:disabled,
.sto-multi-reject:disabled, 
.sto-multi-accept:disabled:hover, 
.sto-multi-reject:disabled:hover{
  background-color: #f4f4f4;
}

.sto-multi-accept:disabled:hover path,
.sto-multi-reject:disabled:hover path {
  fill: #d2d2d1 !important;
}
  .sto-edit:hover {
    background-color: #3d445c;
  }
  .sto-edit:hover path {
    fill: white;
  }
  .sto-edit:disabled path {
    fill: #d2d2d1;
  }
  .sto-edit:disabled,
  .sto-edit:disabled:hover {
    background-color: #f4f4f4;
  }
  .sto-edit:disabled:hover path {
    fill: #d2d2d1;
  }
  .add-btn-rm:disabled path, .add-btn-rm:disabled span {
    fill: #d2d2d1;
    color: #d2d2d1 !important;
  }
  .add-btn-rm:disabled, add-btn-rm:disabled:hover {
    background-color: #f4f4f4;
  }
  .make-Live-rm:disabled, make-live-rm:disabled:hover {
    background-color: #f4f4f4;
  }
  .make-Live-rm:disabled span {
    color: #d2d2d1 !important;
  }
  .add-sto-input-box {
    display: flex;
    flex-direction: column;
    width: 24%;
  }
  .add-sto-input-label {
    font-family: Inter;
    font-size: 13px;
    font-weight: 599;
    line-height: 26px;
    color: #525b7a;
  }
  .add-sto-select-box {
    border: 1px solid #e0e3eb;
    border-radius: 5px;
    padding: 11px 12px;
    color: #acb2ba;
  }
  .add-sto-select-box::placeholder {
    color: #acb2ba;
    line-height: 15.73px;
    font-family: IBM Plex Sans;
  }
  .add-sto-select-box option {
    font-size: 13px;
    font-weight: 400;
  }
  .add-sto-buttons {
    /* border: 1px solid red; */
    display: flex;
    gap: 10px;
    margin-left: auto;
    justify-content: flex-end;
    margin-top: 14px;
  }
  .add-sto-select-status {
    background: #e0e3eb;
    border: none;
    border-radius: 5px;
    font-size: 13px;
    font-weight: 500;
    /* line-height: 20px; */
    font-family: IBM Plex Sans;
    color: #525b7a;
    padding-top: unset;
    padding-left: 12px;
    padding-right: 12px;
    width: 15%;
  }
  .add-sto-select-status option {
    padding: 0px !important;
  }
  select:focus {
    outline: none;
  }
  .add-sto-save {
    background-color: #3d445c;
    color: white;
    font-size: 13px;
    font-weight: 500;
    border-radius: 6px;
    font-family: IBM Plex Sans;
    padding: 7px 15px;
  }
  .add-sto-line {
    border: none;
    border-bottom: 1px solid #f3f4eb;
    margin-top: 14px;
    margin-bottom: 8px;
    opacity: 0.1;
  }
  .add-sto-save-cancel-buttons {
    display: flex;
    justify-content: flex-end;
    gap: 12px;
    margin-top: 10px;
  }
  
  .add-sto-save-cancel-buttons button {
    font-weight: 400;
    font-size: 14px;
    line-height: 17.03px;
    font-family: IBM Plex Sans;
    padding: 7px 25px;
  }
  .add-sto-cancel-button {
    border: 1px solid #e0e3eb;
    border-radius: 6px;
    background-color: #ffffff;
  }
  .add-sto-save-button {
    border: none;
    background-color: #3d445c;
    color: white;
  }
  
  .highcharts-yaxis-labels text, .highcharts-yaxis text {
    font-family: Inter;
  }

  .status-indicator-app {
    border-radius: 50%;
  
    height: 10px;
    width: 10px;
  }
  .approved-status {
    background-color: #00ca14;
  }
  .rejected-status {
    background-color: #ff5555;
  }
  .pending-status {
    background-color: #e0e3eb;
  }
  .awaiting-status {
    background-color: white;
    border: 1px solid #00ca14;
    border-radius: 50%;
  }

  .grp-action-live-btn {
    box-sizing: border-box;
    height: 30px;
    border: none;
    outline: none;
    background-color: #e0e3eb;
    border-radius: 5px;
    color: white;
    padding: 0px 10px;
    font-size: 13px;
  }
  .grp-action-live-enable {
    background-color: #ffffff;
    border: 1px solid #d9d9d9;
    color: #3d445c;
  }
  .grp-action-live-enable:hover {
    background-color: #3d445c;
    color: white;
  }
  .live-btn-enable {
    background-color: #ffffff;
    border: 1px solid #029f00;
    color: #029f00;
    cursor: default;
  }
  .grp-action-live-btn-view {
    margin: unset !important;
  }