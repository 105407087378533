.common-props {
  font-family: Inter;
  width: calc(100% - 20px);
  margin: 10px;
}

.single-select-dd {
  width: 40%;
  // margin-top: 20px;
  margin-bottom: 5px;
  text-align: left;
}

.single-select-container {
  display: flex;
  justify-content: space-between;
}

.dd-lable {
  display: flex;
  color: #525b7a;
  font-size: 13px;
  font-family: Inter;
  font-weight: bold;
  line-height: 20px;
  word-wrap: break-word;
  margin-bottom: 3px;
}

.cron-container {
  height: 30vh;
  margin-top: 5px;
  margin-left: 0;
}

.switch {
  position: relative;
  display: inline-block;
  width: 30px;
  height: 15px;
  margin-left: 10px;
  top: 1px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-color: #e4e4e4;
}

.slider:before {
  position: absolute;
  left: 0;
  content: "";
  height: 15px;
  width: 15px;
  background-color: white;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

input:checked + .slider {
  background-color: #5b80f7;
}

input:focus + .slider {
  box-shadow: 0 0 1px #5b80f7;
}

input:checked + .slider:before {
  -webkit-transform: translateX(15px);
  -ms-transform: translateX(15px);
  transform: translateX(15px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 26px;
}

.slider.round:before {
  border-radius: 50%;
  box-shadow: 0px 0px 1px 1px #e4e4e4;
}

.schedular-header {
  font-size: 16px;
  font-family: Inter;
  font-weight: 500;
  line-height: 24px;
  word-wrap: break-word;
  // margin-top: 25px;
  text-align: left;
  
}

.schedular-subheader {
  color: #666;
  font-size: 12px;
  font-family: Inter;
  font-weight: bold;
  line-height: 18.34px;
  word-wrap: break-word;
  text-align: left;
  margin-left: 5px;
}

.react-js-cron-clear-button {
  background-color: #ec7676 !important;
}

.react-datepicker-popper {
  z-index: 100;
}
.cancel {
  color: #4b4b4b;
  border: 1px solid #3d445c;
  background-color: #ffffff;
}

.save {
  color: white;
  background-color: #3d445c;
}

.action-button {
  font-size: 13px;
  font-family: Inter;
  font-weight: 400;
  line-height: 17.03px;
  word-wrap: break-word;
  width: 120px;
  height: 37px;
  border-radius: 5px;
}

.job-name-input {
  font-family: "Roboto", sans-serif;
  color: #333;
  font-size: 12px;
  margin: 0 auto;
  border-radius: 5px;
  height: 38px;
  background-color: rgb(255, 255, 255);
  width: 100%;
  transition: all 0.3s;
  border: 2px #e0e3eb solid;
  padding: 2px 8px;
  box-sizing: border-box;
}

.job-name-input:hover {
  border-color: hsl(0, 0%, 70%);
}

.job-name-input:focus-visible {
  outline: 2px solid #2684ff !important;
}
.select__control {
  border: 0.5px #e0e3eb solid;
}

.custom-select > div {
  border: 2px #e0e3eb solid;
  border-radius: 5px;
  color: black;
  font-size: 12px;
  font-family: Inter;
  font-weight: 400;
  word-wrap: break-word;
}

.ant-select-selection-overflow-item > span > div,
.ant-select-selector > span {
  color: #515151 !important;
  font-size: 14px !important;
  font-family: Inter !important;
  font-weight: 500 !important;
  // line-height: 18.34px !important;
  word-wrap: break-word !important;
}

.react-js-cron-field > span {
  color: black;
  font-size: 14px;
  font-family: Inter;
  font-weight: 500;
  line-height: 18.34px;
  word-wrap: break-word;
  margin-left: 10px;
  // margin-right: 10px;
}

.react-js-cron-clear-button,
.react-js-cron-clear-button > span {
  background-color: #ec7676 !important;
  color: #3d445c;
  font-size: 14px;
  font-family: Inter;
  font-weight: 500;
  line-height: 18.34px;
  word-wrap: break-word;
  border-radius: 5px;
}

.config-link:hover {
  color: #2f6df0;
  text-decoration: underline;
  text-underline-position: under;
}

.config-line {
  border: none;
  border-top: 1px solid #e0e3eb;
  opacity: 1;
  margin-bottom: 12px;
}

.rules-loading {
  font-family: Inter;
  font-weight: lighter;
  margin-right: 5px;
}