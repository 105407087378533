.category-bar {
  display: flex;
  margin-top: 15px;
  margin-bottom: 10px;
}

.category-button {
  background-color: #e0e3eb;
  color: #3d445c;
  border-radius: unset;
  margin-right: 1px;
  width: 230px;
  border: none;
  padding: 0.3em 1.2em;
  font-size: 1em;
  font-weight: 500;
  font-family: inherit;

  cursor: pointer;
  transition: border-color 0.25s;
}

.category-button-selected {
  background-color: #3d445c;
  color: white;
}

.process-header-1 {
  font-family: Inter;
  font-weight: 400;
  font-size: 13px;
  line-height: 15.73px;
  color: #3d445c;
  cursor: pointer;
}
.process-header-2 {
  font-family: Inter;
  font-size: 13px;
  line-height: 15.73px;
  font-weight: 400;
  color: #777777;
}
.process-header-1:hover {
  color: #2f6df0;
  text-decoration: underline;
  text-underline-position: under;
}
.pp-type{
  background-color: #727272;
  color: #ffffff;
  padding: 3px 15px;
  border-radius: 0;
  z-index: 1;
  font-size: 12px;
  font-family: Inter;
  font-weight: bold;
}
.pp-app-type{
  border-top-left-radius: 6px;
}
.pp-dps-type{
  border-top-right-radius: 6px;
  background-color: #d2d2d1;
}
.pp-active-type{
  background-color: #ffffff;
  color: #E94F1C;
  border-color:#cbd5e1;
  border-bottom: 1px solid #ffffff;
}
