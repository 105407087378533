.filter-toggle {
  display: block;
}
.close {
  border: none;
  outline: none;
  background-color: transparent;
  padding: 0px;
  margin-right: 6px;
}
.close::active {
  border: none;
  outline: none;
}
.filter-header {
  display: flex;
  align-items: center;
}
.filter-header h1 {
  font-size: 17px;
}
.clearall-btn {
  padding: 4px;
  background-color: #f2f2f2;
  font-size: 12px;
  margin-left: auto;
}
.search-container {
  display: flex;
  align-items: center;
  background-color: #f2f2f2;
  border-radius: 6px;
  margin: 20px 0px;
}

.search-container button {
  background-color: transparent;
  padding: 10px 12px;
}

.search-button {
  outline: none;
  border: none;
}

.search-icon {
  background-color: transparent;
}

.search-container input {
  flex-grow: 1;
  background-color: transparent;
  border: none;
  padding: 11px 4px;
}
.search-container input:focus {
  outline: none;
}

.filter-item-select {
  height: 100%;
  border: none;
  width: 100%;
  padding: 5px;
}

.filter-item-select:focus {
  outline: none;
}

.filter-item-select option {
  background-color: white;
  font-size: 12px;
}

.filter-item-select.decorated option:hover {
  background-color: lightblue;
}

.filter-item-select option:focus {
  background: lightblue;
}
.filter-item-sku {
  padding: 4px 16px;
}
.search-2 {
  background-color: white;
  margin: 2px 0px;
  margin-bottom: 8px;
}
.search-2 button {
  padding: 0px;
}
.sku-item {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
}
.sku-item label {
  font-size: 14px;
  color: #747f8d;
}
.sku-item input {
  margin-right: 12px;
}
.location-type {
  display: flex;
  align-items: center;
}
.sku-left-item {
  width: 73%;
}
.units {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 8px;
}
.unit-container {
  margin-right: 18px;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.unit-input {
  margin-right: 4px;
  color: #c3ccd7;
}
.unit-label {
  font-size: 14px;
  color: #c3ccd7;
}
.input-container {
  border: none;
  outline: none;
}
.custom-input {
  height: 30px;
  width: 90%;
  border: none;
  outline: none;
  color: #687483;
}

.calendar-icon {
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  color: #c1cad6;
}
.btn-container {
  display: flex;
  justify-content: center;
}
.apply-btn {
  background-color: #282765;
  color: white;
  border: none;
  padding: 12px;
  margin: 3px;
  width: 25%;
  border-radius: 12px;
  font-size: 14px;
}
.cancel-btn {
  color: #282765;
  margin: 3px;
  border: 1px solid #282765;
  border-radius: 12px;
  width: 25%;
  font-size: 14px;
}
.filter-content {
  display: flex;
  flex-direction: column;
}
.filter-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
}
.filter-header h1 {
  color: #000;
  font-family: IBM Plex Sans;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 131.023%;
}
.filter-right-header button {
  margin-left: 19px;
}

.clear-all-btn {
  padding: 0;
  outline: none;
  background-color: transparent;
  margin-left: 19px;
  color: #000;
  font-family: IBM Plex Sans;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 131.023%;
  border: none;
}
.clear-all-btn:active,
.clear-all-btn:hover,
.clear-all-btn:focus {
  border: none;
  outline: none;
}
.filter-heading {
  color: #1c1d1f;
  font-family: IBM Plex Sans;
  font-size: 11px;
  font-style: normal;
  font-weight: 570;
  line-height: 21px;
  letter-spacing: 0.33px;
  text-transform: uppercase;
  text-align: start;
  margin: 6px 0px 8px 0px;
}
.filter-item {
  display: inline-block;
  box-sizing: border-box;
  padding: 5px;
  width: 100%;
  border: 1px solid #d9d9d9;
  border-radius: 6px;
  margin-bottom: 8px;
}
.filter-legend {
  margin-left: 12px;
  color: #7a7a7a;
  font-family: IBM Plex Sans;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-align: start;
}
.close-btn {
  background-color: white;
  margin: 0;
  padding: 0;
  text-align: center;
  height: 32px;
  width: 32px;
  border-radius: 32px;
  border: 1px solid #d9d9d9;
  margin-left: 19px;
}
.close-btn:focus,
.close-btn:active,
.close-btn:hover {
  outline: none;
  border: 1px solid #d9d9d9;
}

.hide-modal {
  display: none;
}
