.upload-modal-container {
  position: fixed;
  z-index: 2000;
  width: 100vw;
  height: 100vh;
  background-color: rgba(1, 1, 1, 0.2);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.upload-modal {
  background-color: white;
  position: absolute;
  margin: auto;
  width: 46%;
  height: 58%;
  display: flex;
  flex-direction: column;
  padding: 20px 30px;
  border-radius: 12px;
  box-shadow: 0 4px 80px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19) !important;
}
.upload-modal-header {
  font-size: 16px;
  font-family: Inter;
  line-height: 19.36px;
  font-weight: 600;
  color: #1c1c1c;
  text-align: center;
  margin-bottom: 18px;
}
.uploader-body {
  border: 1px solid red;
}
.upload-send-modal {
  padding: 0px;
  width: 44%;
  height: 48%;
  border-radius: 6px;
}
.close-upload-modal {
  margin-top: 12px;
  background-color: aliceblue;
  color: black;
  border: none;
  outline: none;
  padding: 5px 10px;
  margin-left: auto;
  margin-top: auto;
  font-size: 12px;
}
.upload-modal-footer {
  margin-left: auto;
  margin-top: auto;
}
.upload-modal-footer button {
  padding: 6px 18px;
  font-weight: 400;
  font-size: 12px;
  line-height: 17.03px;
  font-family: Inter;
}
.upload-cancel-button {
  border: 1px solid #3d445c;
  background-color: #ffffff;
}
.upload-yes-button {
  margin-left: 12px;
  border: none;
  background-color: #3d445c;
  color: #ffffff;
}
.approval-comments-close-btn {
  margin-left: 12px;
  border: none;
  background-color: #3d445c;
  color: #ffffff;
  font-weight: 400;
  font-size: 12px;
  line-height: 17.03px;
  font-family: Inter;
  padding: 6px 18px;
}
.level-btns {
  align-self: center;
  display: flex;
  align-self: center;
  font-size: 6px;
  width: 21%;
  justify-content: start;
  margin-right: 5px;
}
.level-btns > button {
  margin-right: 5px;
}
.level-button {
  background-color: transparent;
  color: #3d445c;
  font-size: 12px;
  font-family: IBM Plex Sans;
  font-weight: 600;
  line-height: 20px;
}
.active-btn {
  color: #5b80f7;
  background-color: #e8f3fd;
}
.view-button-org {
  border: 1px solid #a3aac2;
  border-radius: 4px;
  color: #3d445c;
  font-family: Inter;
  font-weight: 600;
  font-size: 11px;
  line-height: 20px;
  padding: 1px 10px;
  margin-left: 10px;
}
.view-button-org:hover {
  border: 1px solid #a3aac2 !important;
}
.status-col {
  display: inline-flex;
  align-items: center;
  gap: 20px;
}

.plan-status {
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  gap: 60px;
}
.info-icon {
  color: white;
  background-color: #a3aac2;
  border-radius: 50%;
  // opacity: 0.2;
}
.status-indicator-app {
  border-radius: 50%;
  background-color: #00ca14;
  height: 10px;
  width: 10px;
}

.send-revoke-box {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.send-revoke-input {
  opacity: 0;
  width: 0;
  height: 0;
}

.send-revoke-slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.send-revoke-slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.send-revoke-input:checked + .send-revoke-slider {
  background-color: #029f00;
}

.send-revoke-input:focus + .send-revoke-slider {
  box-shadow: 0 0 1px #029f00;
}

.send-revoke-input:checked + .send-revoke-slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(17px);
}

.send-revoke-slider.send-revoke-round {
  border-radius: 34px;
}

.send-revoke-slider.send-revoke-round:before {
  border-radius: 50%;
}

.small-switch {
  width: 34px;
  height: 17px;
}

.small-switch .send-revoke-slider {
  width: 34px;
  height: 17px;
}

.small-switch .send-revoke-slider:before {
  width: 15px;
  height: 15px;
  left: 1px;
  bottom: 1px;
}
.table-left-bottom {
  display: flex;
  width: 55%;
}

.table-left-bottom-footer p {
  font-size: 12px !important;
  line-height: 19px;
  font-family: Inter;
  font-weight: 400;
  color: #3d445c;
}
.send-modal-header {
  background-color: #f9f9fb;
  font-size: 17px;
  padding: 16px 26px;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  color: #51545e;
  font-weight: 600;
  margin: unset;
  font-family: Inter;
  line-height: 23.58px;
}
.send-modal-body {
  padding: 16px 26px;
  height: 100%;
}
.send-modal-body p {
  color: #69788e;
  font-size: 13px;
  margin-bottom: 10px;
  color: #3d445c;
}
.send-modal-body textarea {
  width: 100%;
  height: 80%;
  margin-top: auto;
  border: 1.2px solid #ccd6e2;
  border-radius: 6px;
}
.send-modal-footer {
  padding: 4px 26px 15px 26px;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  margin-top: auto;
}
.send-modal-footer button {
  padding: 8px 20px;
  font-size: 12px;
  font-family: Inter;
}
.send-cancel-button {
  border: 1px solid #e2e8f0;
  background-color: #ffffff;
}
.send-yes-button {
  margin-left: 12px;
  border: none;
  background-color: #1f222e;
  color: #ffffff;
}
.edit-reason-modal {
  height: 73%;
  width: 42%;
  border-radius: 6px;
}
.approval-comments-modal {
  height: 75%;
}
.edit-reason-modal-header {
  display: flex;
  align-items: center;
  padding: 15px 25px;
  background: #fffbeb;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  gap: 15px;
  border-radius: 6px;
}
.approval-comments-header {
  background-color: #f9f9fb;
}
.edit-reason-modal-header h1 {
  color: #000;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  margin-bottom: unset;
}
.edit-reason-modal-body {
  padding: 10px 25px 5px 25px;
}
.edit-reason-modal-body p {
  font-size: 13px;
  font-family: Inter;
  color: #3d445c;
  line-height: 20px;
  font-weight: 400;
}
.edit-reason-modal-body p:first-child {
  font-weight: 500;
}
.edit-reason-order-quantity {
  padding: 4px 5px;
  margin: unset;
  margin-bottom: 2px;
}
.edit-reason-edited-quantity {
  background: #fcefef;
  color: #dd3838 !important;
  padding: 4px 5px;
  display: inline;
  margin: unset;
  margin-bottom: 2px;
}
.edit-select-container {
  border: 1px solid #e0e3eb;
  padding: 10px;
  color: #a8a8a8;
  font-size: 12px;
  border-radius: 5px;
}
.edit-select-container:active,
.edit-select-container:focus {
  border: 1px solid #e0e3eb;
  outline: none;
}
.edit-select-container::placeholder {
  font-size: 12px;
  font-weight: 400;
  font-family: Inter;
  line-height: 14.52px;
  color: #a0a0a0;
}
.edit-reason-label {
  font-size: 12px;
  font-weight: 400;
  font-family: Inter;
  line-height: 30px;
  color: #3d445c;
}
.edit-reason-textarea {
  margin-top: 0 !important;
  width: 100%;
  flex-grow: 1;
  padding: 10px;
  border: 1px solid #e0e3eb;
  border-radius: 6px;
  font-size: 12px;
  font-weight: 400;
  font-family: Inter;
  box-sizing: border-box;
  color: #a0a0a0;
}
.edit-reason-textarea::placeholder {
  color: #a8a8a8;
}
.edit-reason-textarea:active,
.edit-reason-textarea:focus {
  border: 1px solid #e0e3eb;
  outline: none;
}
.required-asterisk {
  color: red;
  margin-left: 0px;
}
.required-error-msg {
  font-size: 8px;
  color: red;
  font-family: Inter;
  padding-left: 4px;
}
.job-delete-modal {
  padding: 0px;
  width: 40%;
  height: 30%;
  border-radius: 6px;
}

.job-delete-footer {
  padding: 4px 26px 15px 26px;
}
.job-delete-body {
  padding: 15px 26px 10px 26px;
}
.job-delete-body p {
  font-size: 13px;
  line-height: 17.03px;
  font-family: Inter;
  color: #64748b;
}
textarea {
  resize: none;
}

textarea:active,
textarea:focus {
  outline: none;
}

.approval-comments {
  padding: 14px;
  font-size: 13px;
  font-family: Inter;
  color: #3d445c;
  border: 1px solid #e0e3eb;
}
.approval-comments::placeholder {
  color: #a3aac2;
  font-size: 13px;
}
.comments-error-msg {
  min-height: 50px;
  color: red;
  font-size: 9px;
  margin: 0px;
  margin-top: -6px;
  line-height: 0px;
}

.comments-container {
  padding: 0px 10px 6px 15px;
  margin-top: 15px;
  margin-bottom: 15px;
}

.comment-user {
  display: flex;
}

.comment-name-level {
  display: flex;
  flex-direction: column;
  margin-left: 15px;
  justify-content: space-around;
}

.comment-dot {
  display: flex;
  align-items: center;
  margin-left: 10px;
}

.comment-time {
  display: flex;
  align-items: center;
  margin-left: 10px;
}

.comment-text {
  margin-top: 10px;
  margin-left: 55px;
  display: flex;
  align-items: center;
  border-radius: 6px;
  border: 1px solid #e0e3eb;
  background: #fff;
  padding: 5px 20px;
}
.skeleton-comment-text {
  border: none;
  padding: 0;
}
.orders-mgmt-modal {
  border-radius: 4px;
  padding: 0px;
  width: 50%;
  height: 60%;
}
.create-plan-modal-2 {
  height: 90%;
}
.create-plan-modal-3 {
  height: 50%;
}
.create-plan-body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 70%;
  margin: auto;
  text-align: center;
}
.create-plan-step3-text {
  color: #7a7a7a;
  font-size: 12px;
  font-weight: 500;
}
.create-plan-step3-plan-text {
  color: #5a5a5a;
  font-weight: 600;
}
.orders-mgmt-modal-header {
  background-color: #e6e6e6;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}
.orders-mgmt-modal-heading {
  color: #1c1c1c;
  font-weight: 600;
  text-align: center;
  font-size: 16px;
  font-family: Inter;
  padding-top: 14px;
  padding-bottom: 14px;
}
.orders-mgmt-modal-line {
  border: 1px solid #1c1d1f;
  opacity: 0.1;
}
.orders-mgmt-modal-body {
  padding: 14px 36px 20px 36px;
}
.orders-mgmt-select-box {
  padding-top: 18px;
  padding-bottom: 18px;
}
.orders-mgmt-orders-selected {
  font-family: Inter;
  color: #000000;
  font-weight: 600;
  font-size: 14px;
}
.orders-mgmt-select-box {
  border: 1px solid #b3b3b3;
  width: 100%;
  border-radius: 4px;
  color: #8c8c8c;
  font-weight: 500;
  padding: 6px;
  font-size: 12px;
  font-family: IBM Plex Sans;
  margin: 6px 0px 6px 0px;
}
.orders-mgmt-select-box option:disabled {
  color: #b3b3b3;
}
.orders-mgmt-add-order-modal {
  border-radius: 4px;
  padding: 0px;
  width: 45%;
  height: 70%;
}
.orders-mgmt-modal-footer {
  display: flex;
  justify-content: end;
  padding: 10px 36px 18px 36px;
  margin-top: auto;
}
.create-plan-step3-btns-box {
  gap: 6px;
}
.orders-mgmt-modal-footer button {
  border-radius: 4px;
  font-family: Inter;
  font-weight: 500;
  font-size: 12px;
  padding: 4px 19px;
}
.create-plan-next-btn {
  background-color: #1c1d1f;
  color: #ffffff;
}
.create-plan-prev-btn {
  background-color: #d2d2d2;
  color: #1c1d1f;
}
.create-plan-step2-summary {
  background: #f6f8f9;
  padding: 10px 8px 4px 14px;
  border-radius: 6px;
  height: auto;
}
.create-plan-footer {
  margin-top: auto;
  justify-content: space-between;
}
.create-plan-step2-header {
  display: flex;
  justify-content: space-between;
}
.create-plan-step2-header p:first-child {
  color: #3c3c3c;
  font-weight: 500;
  font-family: "Inter", sans-serif;
  font-size: 13px;
}
.create-plan-step2-header p:nth-child(2) {
  font-size: 10px;
  font-weight: 500;
  font-family: "Inter", sans-serif;
  font-style: italic;
  align-self: start;
}
.create-plan-step2-order-tabs {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  margin-top: 8px;
}
.create-plan-order-values {
  border-radius: 5px;
  gap: 5px !important;
  display: flex;
}
.create-plan-step2-download {
  background: #e55454;
  padding: 3px 4px;
  border-radius: 3px;
}
.create-plan-order-values button {
  font-family: Inter;
  font-size: 12px;
  font-weight: 600;
  border-radius: 4px;
  padding: 1px 13px;
  border: 1px solid #bcbec2;
}
.create-plan-order-values button:nth-child(1) {
  color: #005f78;
}
.create-plan-order-values button:nth-child(2) {
  color: #00a556;
}
.create-plan-order-values button:nth-child(3) {
  color: #cb0000;
}
.create-plan-success-data-heading {
  font-family: Inter;
  font-weight: 600;
  color: #000000;
}
.create-plan-success-data {
  font-size: 10px;
  color: #029f00;
  font-style: italic;
  margin: 3px 0px !important;
}
.create-plan-conficts-data {
  color: #d90000;
  font-size: 10px;
  font-style: italic;
  margin: 3px 0px;
}
.add-order-modal-header {
  background-color: #f6f8f9;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  display: flex;
}
.add-order-close-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
  padding: 10px;
  border: 1px solid #d9d9d9;
  border-radius: 50%;
  align-self: center;
  margin-right: 12px;
}
.add-order-heading {
  padding-top: 18px;
  padding-bottom: 18px;
}
.add-order-body {
  padding: 20px 15px 30px 15px;
}
.add-order-upload-heading {
  font-weight: 700;
  font-family: Inter;
  font-size: 12px;
  color: #1c1c1c;
  // margin-top: 20px;
  margin-bottom: 20px;
  text-align: center;
}
.add-order-dwnld-template-btn {
  display: flex;
  align-items: center;
  gap: 5px;
  padding-left: 6px;
}
.add-order-dwnld-template-btn span {
  font-size: 10px;
}
.add-order-footer {
  display: flex;
  justify-content: end;
  align-items: center;
  gap: 8px;
  padding: 16px 20px 16px 20px;
  margin-top: auto;
}
.add-order-footer button {
  border-radius: 4px;
  padding: 6px 16px;
  font-size: 12px;
  font-family: Inter;
  font-weight: 500;
}
.add-order-cancel-btn {
  background-color: #d7d7d7;
  color: #4b4b4b;
}
.add-order-upload-btn {
  background-color: #1c1d1f;
  color: #ffffff;
}
select:disabled
{
  background-color: #fafafa;
}