$light-black: #e2e2e2;
$blue: #555cb8;
$white: #ffffff;
$white-smoke: #f5f5f5;
$light-silver: #d8d8d8;
$inky-storm: #555469;
$gray: #ededed;
$dark-blue: #292666;
$smoke: #efefef;
$shuttle-grey: #566271;
$fog: #d8d6ff;
$silver-sand: #b4bdc6;
$cataline-blue: #2a3f54;
$spun-pearl: #acb2ba;
$light-blue: #add8e6;
$ash: rgba(1, 1, 1, 0.2);
$dark-grey: #262626;
$black: #000;
$eerie-black: #242424;
$slate-blue: #646cff;
$ink-blue: #535bf2;
$nero-black: #1a1a1a;
$witch-blue: #747bff;
$cyan-blue: #213547;
$ghost-white: #f9f9f9;
