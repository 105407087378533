@import "./partials/styles";

:snp {
  font-family: Inter, Avenir, Helvetica, Arial, sans-serif;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  margin: 0;
  color-scheme: light dark;
  color: rgba(255, 255, 255, 0.87);
  background-color: $eerie-black;
  font-synthesis: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;
}

body {
  background-color: #fff;
}
a {
  font-weight: 500;
  color: $slate-blue;
  text-decoration: inherit;
}
a:hover {
  color: $ink-blue;
}

h1 {
  font-size: 3.2em;
  line-height: 1.1;
}

button {
  border-radius: 0.5em;
  border: 1px solid transparent;
  padding: 0.6em 1.2em;
  font-size: 1em;
  font-weight: 500;
  font-family: inherit;
  background-color: $nero-black;
  cursor: pointer;
  transition: border-color 0.25s;
}

@media (prefers-color-scheme: light) {
  :snp {
    color: $cyan-blue;
    background-color: $white;
  }
  a:hover {
    color: $witch-blue;
  }
  button {
    background-color: $ghost-white;
  }
}
.vis-network {
  background-color: antiquewhite;
}