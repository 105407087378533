.w3-light-grey,
.w3-hover-light-grey:hover,
.w3-light-gray,
.w3-hover-light-gray:hover {
  color: #000 !important;
  background-color: #BECAC9 !important;
  margin: 0 30px;
}

/* .flex {
  display: flex;
  justify-content: space-between;
  position: relative;
} */

.w3-container,
.w3-panel {
  padding: 0.01em 16px;
 }
.w3-panel {
  margin-top: 16px;
  margin-bottom: 16px;
}
.w3-blue,
.w3-hover blue:hover {
  color: #fff !important;
  background-color: #6399A5 !important;
}
.w3-round,
.w3-round-medium {
  border-radius: 4px;
}
