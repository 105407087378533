.level-button-rm {
  background-color: #e4e4e4;
  color: #1c1c1c;
  font-size: 13.5px;
  font-family: IBM Plex Sans;
  font-weight: 600;
  line-height: 20px;
  padding: 3px 7px;
  border-radius: 5px;
}
.active-btn-rm {
  color: white;
  background-color: #e94f1b;
  font-weight: 500;
}
.view-button-preprocess-rm {
  border: 1px solid #e0e3eb;
  border-radius: 4px;
  color: white;
  font-family: Inter;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  padding: 0px 10px;
  margin-left: 10px;
  background-color: #d1d1d1;
  color: #454d5b;
}
.view-button-preprocess-rm:hover,
.view-button-preprocess-rm:active,
.view-button-preprocess-rm:focus {
  border: none;
  background-color: #f9652b;
  color: white;
}
.process-btn-rm {
  border: 1px solid #e2e5ec;
  border-radius: 5px;
  padding: 5px 5px;
}

.process-btn-rm:hover {
  background-color: #e0e3eb;
}

.margin-right-rm {
  margin-right: 5px;
}

.level-btns-rm {
  position: absolute;
  top: 0;
  left: 100px;
  width: fit-content;
  box-sizing: border-box;
  padding: 1px 3px;
  border-radius: 5px;
  height: 30px;
  display: flex;
  background-color: #f1f5f9;
  align-items: center;
  justify-content: center;
  column-gap: 5px;
  margin-left: 20px;
}

.level-button-rm-org {
  border-radius: 5px;
  padding: 1px 15px;
  font-size: 13.5px;
  font-family: IBM Plex sans;
  color: #3d445c;
  background-color: transparent;
  font-weight: 600;
}

.level-button-rm-group {
  border-radius: 5px;
  padding: 1px 10px;
  font-size: 13.5px;
  font-family: IBM Plex sans;
  color: #3d445c;
  background-color: transparent;
  font-weight: 600;
}

/* .active-btn-rm {
  background-color:white;
  color: #494949;
  border-radius: 2px;
} */

/* .active-btn-group {
  background-color: #e94f1b;
} */
.header-btns {
  background-color: #f1f5f9;
  border-radius: 5px;
  padding: 1.5px;
  font-weight: bold;
  margin-top: auto;
  margin-bottom: auto;
}
.header-level-button {
  color: #3d445c;
  padding: 2px 10px;
  margin: 2px;
  font-size: 13.5px;
  font-family: IBM Plex sans;
}
.header-active-btn {
  background-color: #ffffff;
  color: #e94f1b;
  border-radius: 2px;
}
