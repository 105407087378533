.invent-hc-main-container {
  height: 100%;
}

.kpi-container {
  display: flex;
  justify-content: center;
  text-align: start;
  box-sizing: border-box;
  height: 15%;
  margin-bottom: 12px;
}

.kpi-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 19.5%;
  margin-left: 3px;
  margin-right: 3px;
  border-radius: 2px;
  padding-left: 9px;
}

.kpi-card-1 {
  border: 1px solid #95ce4b;
  background-color: #ddffb1;
}

.kpi-card-2 {
  border: 1px solid #d2e291;
  background-color: #f5ffcd;
}

.kpi-card-3 {
  border: 1px solid #ffe03d;
  background-color: #fff5d2;
}

.kpi-card-4 {
  border: 1px solid #fcaaaa;
  background-color: #ffe0de;
}

.kpi-card span:first-of-type {
  font-family: Nunito Sans, sans-serif;
  font-weight: 400;
  font-size: 12px;
  color: #555555;
  padding: 0px;
  margin: 0px;
}

.kpi-card span:nth-of-type(2) {
  font-family: IBM Plex Sans;
  font-weight: 600;
  font-size: 13px;
  color: #666666;
}

.invent-hc {
  display: flex;
  justify-content: flex-start;
  margin: auto;
  height: 85%;
}

.invent-hc-container {
  width: 90%;
  height: 100%;
}

.invent-hc-top-container {
  display: flex;
  width: 90%;
  margin: auto;
  align-items: flex-end;
  height: 8%;
}

.invent-hc-bottom container {
  height: 7%;
  padding-top: 0px;
}

.invent-hc-top {
  flex-grow: 1;
  text-align: center;
  font-family: Inter;
  font-weight: 500;
  font-size: 12px;
  padding: 2px;
}

.invent-hc-mid-container {
  display: flex;
  height: 22%;
}

.invent-hc-left {
  padding: 0px;
  width: 5%;
  margin: auto;
}

.invent-hc-left p {
  transform: rotate(270deg);
  font-family: Inter;
  font-weight: 400;
  font-size: 10px;
  line-height: 12.1px;
  text-align: center;
}

.invent-hc-right {
  padding: 0px;
  width: 5%;
  margin: auto;
}

.invent-hc-right p {
  font-family: Inter;
  font-weight: 500;
  font-size: 13px;
  line-height: 15.73px;
  padding-left: 10px;
}

.invent-hc-card {
  color: #1c1d1f;
  font-family: Inter;
  flex-grow: 1;
  text-align: start;
  box-sizing: border-box;
  padding-left: 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.invent-hc-card-1 {
  background-color: #cdf4b9;
}

.invent-hc-card-2 {
  background-color: #cdeca2;
}

.invent-hc-card-3 {
  background-color: #cde38b;
}

.invent-hc-card-4 {
  background-color: #cdeca2;
}

.invent-hc-card-5 {
  background-color: #cde38b;
}

.invent-hc-card-6 {
  background-color: #cddb75;
}

.invent-hc-card-7 {
  background-color: #cde38b;
}

.invent-hc-card-8 {
  background-color: #cddb75;
}

.invent-hc-card-9 {
  background-color: #cdd361;
}

.invent-hc-card h3 {
  padding-top: 5px;
    margin: 0;
  font-weight: 600;
  font-size: 11px;
}

.invent-hc-card div {
  font-weight: 600;
  font-size: 0.85rem;
}

.invent-hc-bottom {
  flex-grow: 1;
  text-align: center;
  font-family: Inter;
  font-weight: 400;
  font-size: 10px;
  padding-top: 4px;
}

.invent-hc-bottom-label {
  display: flex;
  padding-left: 12px;
  align-items: end;
  height: 6%;
}

.invent-hc-bottom-label p {
  font-family: Inter;
  font-weight: 400;
  font-size: 10px;
  line-height: 12.1px;
  margin-right: 8px;
}

.invent-hc-left-container {
  width: 5%;
  display: flex;
  justify-content: center;
  align-items: end;
  margin: 0px;
  padding-bottom: 86px;
  height: 100%;
}

.invent-hc-left-incon {
  display: flex;
  transform: rotate(270deg);
  justify-content: start;
  align-items: center;
}

.invent-hc-left-incon p {
  font-size: 20px;
}

.invent-hc-left-container p {
  font-family: Inter;
  font-weight: 400;
  font-size: 10px;
  line-height: 12.1px;
  margin-right: 8px;
}

@media screen and (max-height: 600px) {
  .kpi-card span:first-of-type {
    font-family: Nunito Sans, sans-serif;
    font-weight: 400;
    font-size: 9px;
    color: #555555;
    padding: 0px;
    margin: 0px;
  }

  .kpi-card span:nth-of-type(2) {
    font-family: IBM Plex Sans;
    font-weight: 600;
    font-size: 10px;
    color: #666666;
  }

  .invent-hc-left-container p {
    font-family: Inter;
    font-weight: 400;
    font-size: 9px;
    line-height: 12.1px;
    margin-right: 8px;
  }

  .invent-hc-left p {
    font-size: 8px;
  }

  .invent-hc-right p {
    font-size: 9px;
    padding-left: 6px;
  }

  .invent-hc-card h3 {
    font-size: 8px;
  }
.invent-hc-card div {
    font-size: 7px;
  }

  .invent-hc-top {
    font-size: 8px;
  }

  .invent-hc-bottom {
    font-size: 8px;
  }

  .invent-hc-left-incon p {
    font-size: 8px;
  }

  .invent-hc-bottom-label p {
    font-size: 8px;
  }

  .invent-hc-bottom-container {
    // border: 1px solid red;
    padding-top: 0px;
    margin-bottom: 4px;
  }
}

@media screen and (min-height: 601px) {
  .invent-hc-card h3 {
    font-weight: 600;
    font-size: 10px;
  }
.invent-hc-card div {
    font-size: 9px;
  }

  .invent-hc-left-container {
    align-items: center;
  }
}

@media screen and (min-height: 740px) {
  .invent-hc-mid-container {
    height: 24%;
  }

  .invent-hc-card h3 {
    font-weight: 600;
    font-size: 12px;
  }

  .kpi-card span:first-of-type {
    font-family: Nunito Sans, sans-serif;
    font-weight: 400;
    font-size: 13px;
    color: #555555;
    padding: 0px;
    margin: 0px;
  }

  .kpi-card span:nth-of-type(2) {
    font-family: IBM Plex Sans;
    font-weight: 600;
    font-size: 13px;
    color: #666666;
  }

  .invent-hc-top {
    flex-grow: 1;
    text-align: center;
    font-family: Inter;
    font-weight: 500;
    font-size: 11px;
    padding: 2px;
  }

  .invent-hc-left-container {
    align-items: center;
  }
}