.iframe-pbi{
  height: calc(100vh - 150px);
}
.inv-mgmt-link {
    font-family: Inter;
    font-weight: 400;
    font-size: 13px;
    line-height: 15.73px;
    color: #3d445c;
    cursor: pointer;
  }
  
  .inv-stats-text {
    font-family: Inter;
    font-size: 13px;
    line-height: 15.73px;
    font-weight: 400;
    color: #777777;
  }
  
  .inv-mgmt-link:hover {
    color: #2f6df0;
    text-decoration: underline;
    text-underline-position: under;
  }