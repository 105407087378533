.react-autosuggest__container {
  background-color: #ffffff;
  position: relative;
  padding: 0px;
  flex-grow: 1;
  border-radius: 5px;
}

.react-autosuggest__input {
  font-size: 12px;
  width: 100%;
  height: 100%;
  color: #858686;
  font-family: Helvetica, sans-serif;
  border: 1px solid #e0e3eb;
  font-weight: 300;
  padding-left: 9px !important;
  padding-right: 9px !important;
  border-radius: 5px;
  padding: 0px;
  // margin-left: 60px;
}
.react-autosuggest__input--focused {
  outline: none;
}
.react-autosuggest__input--open {
  outline: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.react-autosuggest__suggestions-container {
  display: none;
}

.react-autosuggest__suggestions-container--open {
  display: block;
  position: absolute;
  top: 35px;
  width: 100%;
  border: 1px solid #e0e3eb;
  max-height: 190px;
  overflow-y: auto;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  z-index: 9999;
  border-top: none;
  padding-left: 9px;
  padding-right: 9px;
  padding-top: 10px;
  padding-bottom: 9px;
  background-color: white;
}

.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
  text-align: start;
  z-index: 9999;
}
.react-autosuggest__suggestions-container::-webkit-scrollbar {
  width: 7px;
  background-color: none;
}

.react-autosuggest__suggestions-container::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

.react-autosuggest__suggestion {
  padding-top: 9px;
  padding-bottom: 9px;
  z-index: 9999;
  color: #858686;
  font-size: 12px;
  font-family: "Inter", sans-serif;
  cursor: pointer;
}
