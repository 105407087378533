.file-input {
  display: none;
}

.file-input + label {
  font-size: 14px;
  margin-bottom: 0px;
}

.file-input::-webkit-file-upload-button {
  visibility: hidden;
}

.file-input::before {
  content: "Select a file...";
  display: inline-block;
  background-color: #0074d9;
  color: #fff;
  font-size: 14px;
  padding: 8px 12px;
  border-radius: 4px;
  cursor: pointer;
}

.file-input:hover::before {
  background-color: #005ca9;
}

.file-input:active::before {
  background-color: #004b87;
}
.drop-area {
  border: 1px solid #dbdbdb;
  border-style: dashed;
  height: 140px;
  width: 98%;
  height: 60%;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  margin-top: 12px;
  margin-bottom: 12px;
  gap: 20px;
}
.drag-drop {
  display: flex;
  margin-bottom: 12px;
  align-items: flex-start;
}
.drop-area label,
p {
  line-height: 18.73px;
  font-family: Inter;
  font-weight: 500;
  font-size: 13px;
}
.file-input-label {
  display: inline-block;
  margin-bottom: 8px;
  cursor: pointer;
  font-weight: 800 !important;
  color: #172b4d;
  margin-left: 4px;
  text-decoration: underline;
  font-size: 13px;
  line-height: 25px;
  font-family: Inter;
  text-underline-position: under;
  text-decoration-thickness: 1.5px;
}
.upload-icon {
  margin-bottom: 12px;
  color: #80aaff;
}

.drop-area p {
  color: #696968;
}
.upload-btn {
  display: block;
  background-color: #555cb8;
  font-size: 14px;
  color: white;
}
.react-select__menu {
  width: 40%;
}
