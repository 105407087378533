.preprocesses-heading {
  font-family: Inter;
  font-weight: 590;
  font-size: 15px;
  line-height: 18.15px;
  color: #1c1c1c;
  align-self: center;
}
.process-header-1 {
  font-family: Inter;
  font-weight: 400;
  font-size: 13px;
  line-height: 15.73px;
  color: #3d445c;
  cursor: pointer;
}
.process-header-2 {
  font-family: Inter;
  font-size: 13px;
  line-height: 15.73px;
  font-weight: 400;
  color: #777777;
}
.process-header-1:hover,
.add-sto-header:hover {
  color: #2f6df0;
  text-decoration: underline;
  text-underline-position: under;
}
div.MuiTablePagination-toolbar {
  padding-bottom: 10px !important;
}
.add-sto-header {
  cursor: pointer;
}
.process-btn:disabled path {
  opacity: 0.3;
}
.process-view-btn:disabled {
  opacity: 0.3;
}
.process-view-btn:disabled:hover {
  background: white;
  color: #3d445c;
}