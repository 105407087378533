@import "../../partials/_styles.scss";

#notfound {
  position: relative;
  height: 100vh;
}

#notfound .notfound {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.notfound {
  max-width: 32.5em;
  width: 100%;
  line-height: 1.4;
  text-align: center;
}

.notfound .notfound-404 {
  position: relative;
  height: 15em;
}

.notfound .notfound-404 h1 {
  font-family: "Montserrat", sans-serif;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  font-size: 15.75em;
  font-weight: 900;
  margin: 0px;
  color: $dark-grey;
  text-transform: uppercase;
  letter-spacing: -40px;
  margin-left: -20px;
}

.notfound .notfound-404 h1 > span {
  text-shadow: -8px 0px 0px $white;
}

.notfound .notfound-404 h3 {
  font-family: "Cabin", sans-serif;
  position: relative;
  font-size: 1em;
  font-weight: 700;
  text-transform: uppercase;
  color: $dark-grey;
  margin: 0px;
  letter-spacing: .18em;
  padding-left: .37em;
}

.notfound h2 {
  font-family: "Cabin", sans-serif;
  font-size: 1.25em;
  font-weight: 400;
  text-transform: uppercase;
  color: $black;
  margin-top: 0px;
  margin-bottom: 1.56em;
}

@media only screen and (max-width: 767px) {
  .notfound .notfound-404 {
    height: 12.5em;
  }
  .notfound .notfound-404 h1 {
    font-size: 12.5em;
  }
}

@media only screen and (max-width: 480px) {
  .notfound .notfound-404 {
    height: 10.12em;
  }
  .notfound .notfound-404 h1 {
    font-size: 10.12em;
    height: 9.37em;
    line-height: 10.12em;
  }
  .notfound h2 {
    font-size: 1em;
  }
}
