.global-calendar {
  position: absolute;
  right: 4px;
  top: 100px;
  display: inline;
  z-index: 30;
  padding-right: 10px;
}
.react-calendar-custom {
  background-color: white;
  box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.1);
  border: none;
  border-radius: 8px;
  font-family: IBM Plex Sans;
  font-size: 14px;
  line-height: 20px;
  padding: 4px 12px 12px 16px;
  color: #3d445c;
}
.react-calendar__navigation {
  margin-bottom: unset;
  filter: 0px 0px 1px 0px #3d445c;
  margin: 0;
}
.react-calendar__navigation__label {
  color: #3d445c;
  font-weight: 600;
  font-size: 14px;
  font-family: IBM Plex Sans;
}
.react-calendar__month-view__weekdays__weekday > abbr {
  color: red;
  text-transform: capitalize;
  font-weight: 590;
  color: #a3aac2;
  font-size: 14px;
  text-decoration: none;
}

// .react-calendar__month-view__weekdays__weekday > abbr::first-letter {
//   text-transform: capitalize;
// }
// .react-calendar__tile react-calendar__month-view__days__day {
// }
.react-calendar button {
  border-radius: 0;
}
.react-calendar__tile {
  font-size: 14px;
  font-family: IBM Plex Sans;
  line-height: 20px;
}
// .react-calendar__tile :active,
// .react-calendar__tile :focus,
// .react-calendar__tile :hover {
//   background-color: white;
// }
.react-calendar__tile:active > abbr,
.react-calendar__tile:focus > abbr {
  color: white;
}
.react-calendar__tile > abbr:hover {
  background-color: transparent;
  color: #3d445c;
}
.react-calendar__tile:focus > abbr:hover,
.react-calendar__tile:active > abbr:hover {
  color: white;
}
.react-calendar__tile:active > abbr {
  color: #3d445c;
}
.react-calendar__tile--active {
  background-color: white;
  color: #3d445c;
}
.react-calendar__month-view__days__day--weekend {
  color: #3d445c;
}
.planned-day {
  background-color: #e8f3fd;
  /* color: blue; */
}
.holiday {
  background-color: #ffefef;
  color: #3d445c;
}
.holiday:hover {
  background-color: white;
}
.today {
  background-color: white;
  color: #2f6df6;
  font-weight: 600;
}
.today:hover {
  background-color: white;
}
