.styled-card {
  flex-grow: 0 !important;
  min-width: 20%;
  background: #F6F6F6;
  border: 1px solid #FFAB8A !important;
}

.styled-card:hover {
  background-color: #e8f3fd;
}
@media screen and (min-width: 1600px) {
  .kpi-heading {
    font-size: 15px !important;
  }
  .kpi-bold-text {
    font-size: 16px !important;
  }
  .kpi-light-text {
    font-size: 14px !important;
  }
}
