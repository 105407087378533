.reports-container .powerbi-card,
.reports-container .excel-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 10px;
  border: 1px solid #e0e3eb;
  width: 151px;
  height: 170px;
  padding-top: 20px;
  margin: auto;
  position: relative;
}

.reports-container .excel-card:hover {
  background: #f1fff8;
  box-shadow: 0px 10px 25px -5px rgba(0, 0, 0, 0.1);
}

.reports-container .powerbi-card:hover {
  background-color: #fffcf1;
  box-shadow: 0px 10px 25px -5px rgba(0, 0, 0, 0.1);
}

.text-container {
  width: 111px;
  height: 82px;
  flex-shrink: 0;
  color: #000;
  font-family: IBM Plex Sans;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.reports-container {
  padding: 10px;
}

.view-container {
  box-sizing: border-box;
  position: absolute;
  bottom: 0;
  display: flex;
  width: 100%;
  height: 40px;
  padding: 10px 20px;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  background: #e4c444;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.view-btn {
  background: none;
  color: #000;
  font-family: IBM Plex Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.powerbi-dashboards {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(151px, 1fr));
  gap: 10px;
}

.text-powerbi {
  color: #000;
  font-family: IBM Plex Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding-left: 5px;
  padding-bottom: 10px;
}

.download-container {
  box-sizing: border-box;
  position: absolute;
  bottom: 0;
  display: flex;
  width: 100%;
  height: 40px;
  padding: 10px 20px;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  background: #398140;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.text-excel {
  color: #000;
  font-family: IBM Plex Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding-left: 5px;
  padding-bottom: 10px;
  margin-top: 20px;
}

.excel-reports {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(151px, 1fr));
  gap: 10px;
}

.download-btn {
  background: none;
  color: #fff;
  font-family: IBM Plex Sans;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
