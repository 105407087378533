.kpi-card-hover-container {
  border-radius: 4px;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-right: 6px;
  min-height: 30px;
  height: auto;
  font-family: Inter;
  min-width: 150px;
  width: auto;
  color: #3d445c;
  position: absolute;
  background-color: #fff;
  border: 1px solid #e5e7e8;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 9999;
}
.kpi-card-hover-container table {
  width: 100%;
}
.kpi-card-hover-container thead {
  padding-left: 5px;
  padding-bottom: 6px;
}
.kpi-card-hover-container .kpi-card-hover-heading {
  font-size: 14px;
}
.kpi-card-hover-container th {
  padding-left: 10px;
  padding-bottom: 5px;
  font-size: 12px;
  box-shadow: none;
  background-color: transparent;
}
.kpi-hover-heading {
  text-align: start;
  font-size: 12px;
  color: #3d445c;
}
.kpi-card-hover-container td {
  padding-left: 5px;
  padding-right: 8px;
  padding-top: 2px;
  padding-bottom: 2px;
  width: auto;
  white-space: nowrap;
  font-size: 11px;
}

.kpi-card-hover-container table,
.kpi-card-hover-container td,
.kpi-card-hover-container th {
  border-collapse: collapse;
}

.kpi-card-hover-container table td:last-child {
  text-align: end;
}
.plan-metric-click-container {
  display: flex;
  align-items: center;
  gap: 6px;
}

.plan-metric-click-color {
  height: 10px;
  width: 10px;
  display: inline-block;
  color: white;
  border: 1px solid #d9d9d9;
}
.kpi-hover-text {
  font-weight: 400;
}
.kpi-hover-values {
  font-weight: 600;
}

@media screen and (min-width: 1600px) {
  .kpi-hover-heading {
    font-size: 15px !important;
  }
  .kpi-hover-text,
  .kpi-hover-values {
    font-size: 14px !important;
  }
}
